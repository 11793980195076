
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import CatalogueSelectControl from "@/components/controls//base/CatalogueSelectControl.vue";
import TextControl from "@/components/controls/base/TextControl.vue";
import CheckBoxControl from "@/components/controls/base/CheckBoxControl.vue";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import DecimalControl from "@/components/controls/base/DecimalControl.vue";
import api from "@/services/api";
import store from "@/store";
import { ability } from "@/abilities/ability";

export default defineComponent({
  components: {
    CreateEditDialog,
    TextControl,
    CatalogueSelectControl,
    IntegerControl,
    DecimalControl,
    CheckBoxControl,
  },
  mixins: [mountComponentMixin],
  props: {
    saveModelCallback: { required: true },
  },
  computed: {
    isCopyMode() {
      return this.copyMode as boolean;
    },
    hasOrder() {
      return ability.can("view", "Modules.Order");
    },
    hasCargoPriority() {
      return ability.can("view", "Modules.CargoPriority");
    },
    hasCargoQueue() {
      return ability.can("view", "Modules.CargoQueue");
    },
  },
  data() {
    return {
      firstDisplay: true,
      model: null,
      copyMode: false,
      separationWeight: false,
    };
  },
  methods: {
    onChange(e: any) {
      this.setCopyMode(true);
      api
        .get("/CargoRoll/GetEditModel", {
          params: {
            id: this.model.cargoId,
          },
        })
        .then((res) => {
          this.setData(res.data);
        });
    },
    setWeight2() {
      if (!this.model.weight2) this.model.weight2 = this.model.weight;
    },
    calcWeightSum() {
      this.model.totalWeight = this.model.count * this.model.weight;
    },
    calcCount() {
      this.model.count = Math.floor(this.model.totalWeight / this.model.weight);
      this.calcWeightSum();
    },
    setData(data: any) {
      delete data["id"];
      Object.assign(this.model, data);
      this.model.weight2 = this.model.weight;
    },
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
      this.model = this.dialog().createEditModel;
      this.firstDisplay = true;
      this.setCopyMode(false);
      this.model.type = 0;

      const settings = (store as any).state.settings.settings;
      if (settings) {
        this.separationWeight = settings.separationWeight;
      }
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },
    setCopyMode(enabled: any) {
      this.copyMode = enabled;
    },
  },
});
